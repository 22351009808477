import React from "react";
import { Box, Heading } from "theme-ui";

const Settings = () => {
  return (
    <Box>
      <Heading as="h1">Impostazioni</Heading>
    </Box>
  );
};

export default Settings;
